import {CustomFormFieldSizes, CustomFormFieldTypes, UserRole} from "./enums";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import { ComponentType } from "react";

export interface CustomFormFieldOption {
    label: string;
    value: any;
}

export interface CustomFormField {
    key: string;
    label: string;
    kind: CustomFormFieldTypes; // can't use key "type"
    size: CustomFormFieldSizes;
    options?: CustomFormFieldOption[];
    multilineRows?: number;
    // options?: any; // this is where multiple line number goes.
    readonly?: boolean;
    endAdornment?: any;
    toggleIncluded? : { key: string, editable: boolean};
    passthru?: ComponentType<any>; // replace this with a react component.
    passthruProps?: any;
    subform?: SubForm;
}

export interface SubForm {
    fields?: CustomFormField[]
    columns?: GridColDef[]
}

// export interface CustomColumn {
//     index: number;
//     key: string;
//     width: number;
//     enabled: boolean;
// }

export interface DataController<T> {
    getAll: () => Promise<T[]>
    getOne: (id: string) => Promise<T>
    create: (item: T) => Promise<T>
    update: (id: string, item: T) => Promise<T>
}

export interface IUser {

    _id: string;
    name: string;
    email_address: string;
    phone_number: string;
    role: UserRole;
    userAgentLog?: string[];
    isDeactivated?: boolean;
    activeSessions: ISession[];
    projects: IProject[];
}

export interface ISession {
    _id: string;
    user: IUser;
    userAgent: string;
    ipAddress: string;
    environment: "production" | "sandbox"; // this is for push notifications
    iOSDeviceToken?: string;
    androidDeviceToken?: string;
}

export interface ICondition {
    key: string;
    op: "=" | "≠",
    value: any // could this be string | number | boolean ?
}

export interface SurveyObjectQuestion {
    questionText: string;
    key: string;
    type: {
        type: 'text' | 'toggle' | 'select' | 'photo' | 'location' | 'document'| 'multiSelect' | 'geoCoordinate' | 'instruction' | 'table'; // Union type for both question types
        defaultValue?: boolean | string;
        options?: string[]; // Only applicable to 'select' type, and now as the 'table' header
        shownLabels?: string[]; // Only applicable to 'table' type
        overflowLabels?: string[]; // Only applicable to 'table' type
    };
    // id: string;
    questionInstruction?: string;
    conditions: ICondition[];
    required: boolean | ICondition | ICondition[];
    showInStandardReport: boolean | ICondition | ICondition[];
    showInExceptionReport: boolean | ICondition | ICondition[];
    showInExceptionCount: boolean | ICondition | ICondition[];
    requiredConditionFlags?: string[];
}
export interface SurveyObject {
    key: string;
    location: string;
    isConditional: boolean;
    questions: SurveyObjectQuestion[];
    parent?: string;
    conditionFlags?: { [key: string]: boolean };
    emptyIntentionally?: boolean;
}

export interface SiteContactPhone {
    label: string;
    number: string;
}

export interface ISurvey {
    assigned_user_id?: any;
    coordinator_user_id?: any;
    caseNumber: string;
    content: SurveyObject[];
    locations: string[];
    answers?: ISurveyAnswer[];

    surveyName?: string;
    customerTicketNumber?: string;
    surveyDate?: Date;

    surveyDate_year?: number;
    surveyDate_month?: number;
    surveyDate_day?: number;
    surveyDate_hour?: number;
    surveyDate_minute?: number;

    siteContactPhone?: string | SiteContactPhone[];
    siteContactEmail?: string;
    siteContactName?: string;

    company?: ICompany | string;
    project?: IProject | string;

    companyName?: string; // TODO: remove this
    projectName?: string; // TODO: remove this
    locationName?: string;
    surveyOwner?: string;

    address?: string;

    isArchived?: boolean;

    conditionFlags?: { [key: string]: boolean };
    isTemplate?: boolean;

    notes?: string;
}

export interface ToggleAnswer {
    toggle: {
        data: boolean;
    };
}

export interface TextAnswer {
    text: {
        data: string;
    };
}

export interface SelectAnswer {
    select: {
        data: string;
    };
}

export interface MultiSelectAnswer {
    multiSelect: {
        data: string[];
    }
}

export interface PhotoData {
    key: string;
    type: string;
    bucket: string;
    directory?: string;
    filename?: string;
}

export interface PhotoAnswer {
    photo: {
        data: PhotoData[];
    };
}

export interface DocumentData {
    key: string;
    type: string;
    bucket: string;
}

export interface DocumentAnswer {
    fileUpload: {
        data: DocumentData[];
    }
}

export interface GeoCoordinateAnswer {
    geoCoordinate: {
        data : {
            latitude: Number,
            longitude: Number
        }
    }
}

// Union type for all question types
export type Answer = ToggleAnswer | TextAnswer | SelectAnswer | PhotoAnswer | GeoCoordinateAnswer | DocumentAnswer | MultiSelectAnswer;

export type QuestionAnswer = { [key: string]: Answer };
export type ObjectAnswer = {
    values: QuestionAnswer;
    conditionalExists?: boolean;
    label?: string;
};

// The interface for the entire JSON object
export interface SurveyAnswerObject {
    [key: string]: ObjectAnswer;
}

export interface SurveyReport {
    date: Date;
    bucket: string;
    key: string;
    type: string;
    requestedBy?: string | IUser;
}

export interface Clone {
    parent?: string;
    key: string;
}

export interface ISurveyAnswer {
    _id: string;
    updatedBy: string | IUser;
    survey_id: any;
    answers: SurveyAnswerObject;
    reports: SurveyReport[];
    isCompleted: boolean;
    clones: { [key: string]: Clone[] };
    updatedAt: Date;
    overriddenOrderKeys?: string[];
}


export interface IQuickQuestion {
    _id: string;
    content: SurveyObjectQuestion;
}

export interface IQuickObject {
    content: SurveyObject;
}

export interface IQuickQuestionGroup {
    name: string;
    content: SurveyObjectQuestion[];
}

export interface IWebHook {
    url: string
    lastStatusCode?: number
    lastStatusMessage?: string
    lastAttempt?: Date
    failedAttempts?: number
    maxRetryCount: number
    headers?: { [key: string]: string }
    created_by?: IUser
    enabled: boolean
    events: string[] // TODO: make this an enum this is a list of the events that will trigger the webhook
}

export interface ICompany {
    name: string
    projects: (IProject | string)[] // | string[]
}

export interface IProject {
    name: string,
    company: ICompany | string,

    // bucket_name and key for the report logo.
    logoBucket: string,
    logoKey: string
    // logo?: IProjectLogo
}




export enum SurveyWarningType {
    warning,
    error
}
export interface SurveyWarning {
    type: SurveyWarningType,
    message: string
}



export interface ISentEmail {
    to: string;
    bcc?: string;
    cc?: string;
    subject: string;
    text?: string;
    html?: string;
    attachmentCount?: number;
    confirmation?: string;
}

export interface ICaseAttachment {
    key: string;
    bucket: string;
    filename: string;
    createdAt: Date;
    createdBy: string | IUser;
}

export interface ICase {
    // assigned_user: string;
    assigned_user_id?: string | IUser;
    coordinator_user_id?: string | IUser;
    caseNumber: string;

    company?: ICompany | string;
    project?: IProject | string;

    surveyOwner?: string;
    address?: string;

    isArchived?: boolean;

    technicianClosingNotes?: string;
    isClosed?: boolean;

    siteContactName?: string;
    siteContactPhone?: string | SiteContactPhone[];
    siteContactEmail?: string;

    salesforceId?: string;

    attachedMaterials: ICaseAttachment[];

    serviceDescription?: string;
    specificInstructions?: string;

    serviceDateTime?: Date;
    serviceDateTimeEnd?: Date;
    slaDateTime?: Date;
}


export interface ITimeEntry {
    checkInDate: Date;
    checkInIsManual: boolean;
    checkInPerformedBy: IUser | string;
    checkOutDate: Date | null;
    checkOutIsManual: boolean | null;
    checkOutPerformedBy: IUser | string | null;

    // location: Geolocation;
    caseItem: ICase | string;
    assignedTech: IUser | string;

    id?: string; // this is the device id. not used for mongo.
}

export interface ICaseComment {
    content: string;
    created_by: string | IUser;
    caseItem: string | ICase;
}


export interface ICaseDeliverable {
    created_by: string | IUser;
    caseItem: string | ICase;

    filename: string;
    bucket: string;
    key: string;
}

export interface ISurveyMutex {
    session: string | ISession;
    survey: string | ISurvey
}

export enum CaseAcceptance {
    Confirm = "Confirm",
    Decline = "Decline"
}

export interface ICaseAnswer {
    caseNumber: string;

    isCaseClosed: Boolean;
    closingNotes?: string;

    onMyWayTimestamp?: Date;
    etaTimestamp?: Date;

    caseAcceptance?: CaseAcceptance;
    caseDeclinedReason?: string;

    returnMerchandiseApprovals? : string[];
}

export enum ICaseExpenseStatus {
    pending = "pending",
    approved = "approved",
    rejected = "rejected"
}


// needs to have an apporved/rejected by
export interface ICaseExpenseEvent {
    status: ICaseExpenseStatus;
    performed_by: string | IUser;
}

export interface ICaseExpenseReceipt {
    bucket: string;
    key: string;
}

export interface ICaseExpense {
    // _id: string; // does this mess with mongoose...?
    created_by: string | IUser;
    caseItem: string | ICase;
    date: Date;
    events: ICaseExpenseEvent[];

    category: string;
    notes: string;

    softDeleted: boolean; // this is so admins can delete expenses
    amount: number; // this will be in usd.
    // currency: string; // this has to be an enum for the supported currencies.

    receipts: ICaseExpenseReceipt[];

    // read_by: (string| IUser)[]; // array of user ids


    id?: string; // this is the device id. not used for mongo.


}
