import {CustomFormField, ICase, ISurvey, IUser, SiteContactPhone} from "../../../types/interfaces";
import React from "react";
import {
    Box,
    Button, IconButton, InputAdornment,
    List,
    ListItem, ListItemIcon,
    ListItemText,
    ListSubheader,
    Modal,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";

export const LongFormCaseTextField = (props : { caseItem: ICase, setCase: (caseItem: ICase) => void, field: CustomFormField }) => {

    const [open, setOpen] = React.useState<boolean>(false);
    let key = props.field.key as keyof ICase;
    const [text, setText] = React.useState<string>((props.caseItem[key] as (string | undefined)) || "");

    return (<>
        <TextField
            label={props.field.label}
            id={props.field.label + "-readonly-text"}
            value={text}
            disabled={true}
            fullWidth={true}
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    <Button
                        type={"button"}
                        variant={"outlined"}
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        <FontAwesomeIcon icon={faPencil} />
                    </Button>
                </InputAdornment>,
            }}
        />
        <Modal open={open} onClose={() => {
            setOpen(false);
        }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '600px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                    {props.field.label}
                </Typography>

                <Stack direction={'column'} spacing={2}>
                    <TextField
                        id={props.field.label + "-multiline"}
                        label={props.field.label}
                        multiline
                        rows={10}
                        value={text}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setText(event.target.value);
                        }}
                    />

                    <Button variant="outlined" color="primary" size={"small"} fullWidth={true} onClick={async () => {
                        props.setCase({
                            ...props.caseItem,
                            [key]: text,
                        });
                        setOpen(false);

                    }}>Save</Button>
                </Stack>
            </Box>
        </Modal>
    </>);
}






export const LongFormSurveyTextField = (props : { item: ISurvey, setItem: (item: ISurvey) => void, field: CustomFormField }) => {

    const [open, setOpen] = React.useState<boolean>(false);
    let key = props.field.key as keyof ISurvey;
    const [text, setText] = React.useState<string>((props.item[key] as (string | undefined)) || "");

    return (<>
        <TextField
            label={props.field.label}
            id={props.field.label + "-readonly-text"}
            value={text}
            disabled={true}
            fullWidth={true}
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    <Button
                        type={"button"}
                        variant={"outlined"}
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        <FontAwesomeIcon icon={faPencil} />
                    </Button>
                </InputAdornment>,
            }}
        />
        <Modal open={open} onClose={() => {
            setOpen(false);
        }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '600px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                    {props.field.label}
                </Typography>

                <Stack direction={'column'} spacing={2}>
                    <TextField
                        id={props.field.label + "-multiline"}
                        label={props.field.label}
                        multiline
                        rows={10}
                        value={text}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setText(event.target.value);
                        }}
                    />

                    <Button variant="outlined" color="primary" size={"small"} fullWidth={true} onClick={async () => {
                        props.setItem({
                            ...props.item,
                            [key]: text,
                        });
                        setOpen(false);

                    }}>Save</Button>
                </Stack>
            </Box>
        </Modal>
    </>);
}
