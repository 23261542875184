import {
    DataController, ICase, ICaseAnswer, ICaseComment, ICaseDeliverable, ICaseExpense, ITimeEntry
} from "../../types/interfaces";
import {IPaginatedSurveys, ISurveyFilter} from "../Surveys/SurveyDataController";
import {IPaginated} from "../Bank/BankDataController_QuickObject";
import {ICaseFilter} from "./CaseManagementView";

export type ICaseWithId = ICase & { _id?: string };

export class CaseDataController implements DataController<ICaseWithId> {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }

    public async getAll(): Promise<ICaseWithId[]> {
        let results = await fetch(`${this.baseURL}/api/cases`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        return await data; // this doesn't seem to type check.
    }

    public async getPaginated(filter: ICaseFilter): Promise<IPaginated<ICase>> {
        console.log("getPaginated", filter)
        // , startDateFilter: moment.Moment | null, endDateFilter: moment.Moment | null
        // &startDateFilter=${startDateFilter}&endDateFilter=${endDateFilter}
        let url = `${this.baseURL}/api/cases/paginated/?page=${filter.page}&limit=${filter.pageSize}&filter=${filter.searchText}`;

        // if (filter.isTemplate !== undefined) {
        //     url += `&isTemplate=${filter.isTemplate}`;
        // }
        if (filter.isArchived !== undefined) {
            url += `&isArchived=${filter.isArchived}`;
        }

        // if (filter.filterStart_year !== null) {
        //     url += `&filterStart_year=${filter.filterStart_year}`;
        // }
        // if (filter.filterStart_month !== null) {
        //     url += `&filterStart_month=${filter.filterStart_month}`;
        // }
        // if (filter.filterStart_day !== null) {
        //     url += `&filterStart_day=${filter.filterStart_day}`;
        // }
        // if (filter.filterEnd_year !== null) {
        //     url += `&filterEnd_year=${filter.filterEnd_year}`;
        // }
        // if (filter.filterEnd_month !== null) {
        //     url += `&filterEnd_month=${filter.filterEnd_month}`;
        // }
        // if (filter.filterEnd_day !== null) {
        //     url += `&filterEnd_day=${filter.filterEnd_day}`;
        // }





        console.log(`url: ${url}`);

        let results = await fetch(url, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });

        let data = await results.json();
        return await data; // this doesn't seem to type check.
    }

    public async getAllArchived(): Promise<ICaseWithId[]> {
        let results = await fetch(`${this.baseURL}/api/cases/ArchiveList`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        return await data; // this doesn't seem to type check.
    }

    public async getOne(id: String): Promise<ICaseWithId> {
        let results = await fetch(`${this.baseURL}/api/cases/${id}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        // console.log(data);
        return await data; // this doesn't seem to type check.
    }

    public async create(item: ICase): Promise<ICaseWithId> {
        let results = await fetch(`${this.baseURL}/api/cases/`, {
            method: "POST",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        });
        let data = await results.json();
        console.log(data);
        return await data.item; // this doesn't seem to type check.
    }

    public async import(item: ICaseWithId, sourceSurveyId: string): Promise<ICaseWithId> {
        let results = await fetch(`${this.baseURL}/api/cases/create`, {
            method: "POST",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({doc: item, sourceSurveyId: sourceSurveyId})
        });
        let data = await results.json();
        console.log(data);
        return await data.item; // this doesn't seem to type check.
    }

    public async update(id: string, item: ICaseWithId): Promise<ICaseWithId> {
        let results = await fetch(`${this.baseURL}/api/cases/${id}`, {
            method: "PUT",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        });

        // handle when results is 304
        if (results.status === 304) {
            console.log("304");
            return item;
        }

        console.log(results)
        let data = await results.json();
        console.log(data);
        return await data; // this doesn't seem to type check. change to .item when api updated
    }

    public async delete(id: string): Promise<ICaseWithId> {
        let results = await fetch(`${this.baseURL}/api/cases/${id}`, {
            method: "DELETE",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            }
            // body: JSON.stringify(item)
        });

        console.log(results)
        let data = await results.json();
        console.log(data);
        return await data; // this doesn't seem to type check. change to .item when api updated
    }

    // public async downloadExport(id: string): Promise<string> {
    //     let results = await fetch(`${this.baseURL}/api/cases/export/${id}`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.text();
    //     console.log(data);
    //
    //     return data;
    // }

}



export class CaseAnswerDataController {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }


    public async getOne(id: string): Promise<ICaseAnswer> {
        // this id is actually caseNumber
        let results = await fetch(`${this.baseURL}/api/caseAnswers/${encodeURIComponent(id)}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        // console.log(data);
        return await data; // this doesn't seem to type check.
    }



}








export class CaseCommentDataController {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }

    // public async getAll(): Promise<ICaseWithId[]> {
    //     let results = await fetch(`${this.baseURL}/api/casesComments`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.json();
    //     return await data; // this doesn't seem to type check.
    // }

    // public async getAllArchived(): Promise<ICaseWithId[]> {
    //     let results = await fetch(`${this.baseURL}/api/cases/ArchiveList`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.json();
    //     return await data; // this doesn't seem to type check.
    // }

    public async getOne(id: String): Promise<ICaseComment[]> {
        let results = await fetch(`${this.baseURL}/api/caseComments/${id}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        // console.log(data);
        return await data; // this doesn't seem to type check.
    }

    public async create(id: string, item: ICaseComment): Promise<ICaseComment> {
        let results = await fetch(`${this.baseURL}/api/caseComments/${id}`, {
            method: "POST",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        });
        let data = await results.json();
        console.log(data);
        return await data; // this doesn't seem to type check.
    }
    //
    // public async import(item: ICaseWithId, sourceSurveyId: string): Promise<ICaseWithId> {
    //     let results = await fetch(`${this.baseURL}/api/cases/create`, {
    //         method: "POST",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify({doc: item, sourceSurveyId: sourceSurveyId})
    //     });
    //     let data = await results.json();
    //     console.log(data);
    //     return await data.item; // this doesn't seem to type check.
    // }
    //
    // public async update(id: string, item: ICaseWithId): Promise<ICaseWithId> {
    //     let results = await fetch(`${this.baseURL}/api/cases/${id}`, {
    //         method: "PUT",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify(item)
    //     });
    //
    //     // handle when results is 304
    //     if (results.status === 304) {
    //         console.log("304");
    //         return item;
    //     }
    //
    //     console.log(results)
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check. change to .item when api updated
    // }
    //
    // public async delete(id: string): Promise<ICaseWithId> {
    //     let results = await fetch(`${this.baseURL}/api/cases/${id}`, {
    //         method: "DELETE",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         }
    //         // body: JSON.stringify(item)
    //     });
    //
    //     console.log(results)
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check. change to .item when api updated
    // }
    //
    // public async downloadExport(id: string): Promise<string> {
    //     let results = await fetch(`${this.baseURL}/api/cases/export/${id}`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.text();
    //     console.log(data);
    //
    //     return data;
    // }

}




export class CaseDeliverablesDataController {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }

    // public async getAll(): Promise<ICaseWithId[]> {
    //     let results = await fetch(`${this.baseURL}/api/casesComments`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.json();
    //     return await data; // this doesn't seem to type check.
    // }

    // public async getAllArchived(): Promise<ICaseWithId[]> {
    //     let results = await fetch(`${this.baseURL}/api/cases/ArchiveList`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.json();
    //     return await data; // this doesn't seem to type check.
    // }

    public async getOne(id: String): Promise<ICaseDeliverable[]> {
        let results = await fetch(`${this.baseURL}/api/caseDeliverables/${id}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        // console.log(data);
        return await data; // this doesn't seem to type check.
    }

    // public async create(id: string, item: ICaseComment): Promise<ICaseComment> {
    //     let results = await fetch(`${this.baseURL}/api/caseComments/${id}`, {
    //         method: "POST",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify(item)
    //     });
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check.
    // }
    //
    // public async import(item: ICaseWithId, sourceSurveyId: string): Promise<ICaseWithId> {
    //     let results = await fetch(`${this.baseURL}/api/cases/create`, {
    //         method: "POST",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify({doc: item, sourceSurveyId: sourceSurveyId})
    //     });
    //     let data = await results.json();
    //     console.log(data);
    //     return await data.item; // this doesn't seem to type check.
    // }
    //
    // public async update(id: string, item: ICaseWithId): Promise<ICaseWithId> {
    //     let results = await fetch(`${this.baseURL}/api/cases/${id}`, {
    //         method: "PUT",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify(item)
    //     });
    //
    //     // handle when results is 304
    //     if (results.status === 304) {
    //         console.log("304");
    //         return item;
    //     }
    //
    //     console.log(results)
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check. change to .item when api updated
    // }
    //
    // public async delete(id: string): Promise<ICaseWithId> {
    //     let results = await fetch(`${this.baseURL}/api/cases/${id}`, {
    //         method: "DELETE",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         }
    //         // body: JSON.stringify(item)
    //     });
    //
    //     console.log(results)
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check. change to .item when api updated
    // }
    //
    // public async downloadExport(id: string): Promise<string> {
    //     let results = await fetch(`${this.baseURL}/api/cases/export/${id}`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.text();
    //     console.log(data);
    //
    //     return data;
    // }

}






export class CaseTimeEntriesDataController {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }

    // public async getAll(): Promise<ICaseWithId[]> {
    //     let results = await fetch(`${this.baseURL}/api/casesComments`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.json();
    //     return await data; // this doesn't seem to type check.
    // }

    // public async getAllArchived(): Promise<ICaseWithId[]> {
    //     let results = await fetch(`${this.baseURL}/api/cases/ArchiveList`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.json();
    //     return await data; // this doesn't seem to type check.
    // }

    public async getOne(id: String): Promise<ITimeEntry[]> {
        let results = await fetch(`${this.baseURL}/api/timeEntries/case/${id}/all`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        // console.log(data);
        return await data; // this doesn't seem to type check.
    }

    // public async create(id: string, item: ICaseComment): Promise<ICaseComment> {
    //     let results = await fetch(`${this.baseURL}/api/caseComments/${id}`, {
    //         method: "POST",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify(item)
    //     });
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check.
    // }
    //
    // public async import(item: ICaseWithId, sourceSurveyId: string): Promise<ICaseWithId> {
    //     let results = await fetch(`${this.baseURL}/api/cases/create`, {
    //         method: "POST",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify({doc: item, sourceSurveyId: sourceSurveyId})
    //     });
    //     let data = await results.json();
    //     console.log(data);
    //     return await data.item; // this doesn't seem to type check.
    // }
    //
    // public async update(id: string, item: ICaseWithId): Promise<ICaseWithId> {
    //     let results = await fetch(`${this.baseURL}/api/cases/${id}`, {
    //         method: "PUT",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify(item)
    //     });
    //
    //     // handle when results is 304
    //     if (results.status === 304) {
    //         console.log("304");
    //         return item;
    //     }
    //
    //     console.log(results)
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check. change to .item when api updated
    // }
    //
    // public async delete(id: string): Promise<ICaseWithId> {
    //     let results = await fetch(`${this.baseURL}/api/cases/${id}`, {
    //         method: "DELETE",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         }
    //         // body: JSON.stringify(item)
    //     });
    //
    //     console.log(results)
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check. change to .item when api updated
    // }
    //
    // public async downloadExport(id: string): Promise<string> {
    //     let results = await fetch(`${this.baseURL}/api/cases/export/${id}`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.text();
    //     console.log(data);
    //
    //     return data;
    // }

}




export class CaseExpensesDataController {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }

    // public async getAll(): Promise<ICaseWithId[]> {
    //     let results = await fetch(`${this.baseURL}/api/casesComments`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.json();
    //     return await data; // this doesn't seem to type check.
    // }

    // public async getAllArchived(): Promise<ICaseWithId[]> {
    //     let results = await fetch(`${this.baseURL}/api/cases/ArchiveList`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.json();
    //     return await data; // this doesn't seem to type check.
    // }

    public async getOne(id: String): Promise<ICaseExpense[]> {
        let results = await fetch(`${this.baseURL}/api/caseExpenses/${id}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        // console.log(data);
        return await data; // this doesn't seem to type check.
    }

    // public async create(id: string, item: ICaseComment): Promise<ICaseComment> {
    //     let results = await fetch(`${this.baseURL}/api/caseComments/${id}`, {
    //         method: "POST",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify(item)
    //     });
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check.
    // }
    //
    // public async import(item: ICaseWithId, sourceSurveyId: string): Promise<ICaseWithId> {
    //     let results = await fetch(`${this.baseURL}/api/cases/create`, {
    //         method: "POST",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify({doc: item, sourceSurveyId: sourceSurveyId})
    //     });
    //     let data = await results.json();
    //     console.log(data);
    //     return await data.item; // this doesn't seem to type check.
    // }
    //
    // public async update(id: string, item: ICaseWithId): Promise<ICaseWithId> {
    //     let results = await fetch(`${this.baseURL}/api/cases/${id}`, {
    //         method: "PUT",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify(item)
    //     });
    //
    //     // handle when results is 304
    //     if (results.status === 304) {
    //         console.log("304");
    //         return item;
    //     }
    //
    //     console.log(results)
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check. change to .item when api updated
    // }
    //
    // public async delete(id: string): Promise<ICaseWithId> {
    //     let results = await fetch(`${this.baseURL}/api/cases/${id}`, {
    //         method: "DELETE",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         }
    //         // body: JSON.stringify(item)
    //     });
    //
    //     console.log(results)
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check. change to .item when api updated
    // }
    //
    // public async downloadExport(id: string): Promise<string> {
    //     let results = await fetch(`${this.baseURL}/api/cases/export/${id}`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.text();
    //     console.log(data);
    //
    //     return data;
    // }

}



